<template>
	<div
		class=""
	>
		<div class="">
			<h6 class="text-left">영업점</h6>
			<div class="top-line-identify text-left box pa-10 bg-eee font-weight-bold">
				{{ upper_name  }}
			</div>
		</div>
		<div class="mt-10">
			<h6 class="text-left">매출 합계</h6>
			<table class="table top-line-identify">
				<thead>
				<tr>
					<th>매출 시작일</th>
					<th>매출 종료일</th>
					<th>정산 기준일</th>
					<th>승인금액</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{ item.fromDate }}</td>
					<td>{{ item.toDate }}</td>
					<td>{{ item.calDate }}</td>
					<td>{{ summary.totalApprovalAmount }}</td>
					<td>{{ summary.totalCalAmount }}</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-10 full-height overflow-y-auto">
			<h6 class="justify-space-between">
				매출 내역

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10  size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</h6>
			<table class="table top-line-identify">
				<colgroup>
					<col width="60px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>승인일시</th>
					<th>정산 기준일</th>
					<th>서비스 타입</th>
					<th>가맹점</th>

					<th>승인번호</th>
					<th>할부</th>
					<th>승인금액</th>
					<th>수수료율</th>
					<th>수수료(부가세포함)</th>

					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'item_' + index"
				>
					<td>{{  item.idx }}</td>
					<td>{{ item.approvalDate }}</td>
					<td>{{ item.calDate}}</td>
					<td>{{ item.type }}</td>
					<td>{{ item.shopName }}</td>

					<td>{{ item.approvalCode }}</td>
					<td>{{ item.installment}}</td>
					<td class="text-right">{{ item.approvalAmount}}</td>
					<td class="text-right">{{ item.fee }}</td>
					<td class="text-right">{{ item.feeVat }}</td>

					<td class="text-right">{{ item.calAmount }}</td>
				</tr>
				</tbody>
			</table>

			<pagination
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></pagination>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"
		></Excel>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import { Axios } from "@/resources/axios/axios";
import { date } from "@/resources/date/date";
import {codes} from "@/resources/config/codes";
import Excel from "@/components/Excel";

export default {
	name: 'SettlementMerchantDetail'
	,components: {Excel, Pagination }
	,props: ['item', 'user']
	,data: function(){
		return {
			Axios: Axios
			,date: date
			,codes: codes
			,items: []
			,search: {
				page: 1
				,size: 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
				,state: ''
				,search_date_type: 'today'
				, distributorIdx: this.user.distributorIdx
				, branchIdx: this.user.branchIdx
				, agencyIdx: this.user.agencyIdx
				, resellerIdx: this.user.resellerIdx
			}
			,summary: {}
			,supBranch: {}
			,is_excel: false
			,excel_data: {
				name: '가맹점 정산 상세 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'idx'}
					,{ key: 0, name: '승인 일시', column: 'approvalDate'}
					,{ key: 0, name: '정산 기준일', column: 'calDate'}
					,{ key: 0, name: '서비스 타입', column: 'type'}
					,{ key: 0, name: '가맹점', column: 'shopName'}
					,{ key: 0, name: '카드사', column: 'cardName'}
					,{ key: 0, name: '카드번호', column: 'cardNumber'}
					,{ key: 0, name: '승인번호', column: 'approvalCode'}
					,{ key: 0, name: '할부', column: 'installment'}
					,{ key: 0, name: '승인금액', column: 'approvalAmount'}
					,{ key: 0, name: '수수료율', column: 'fee'}
					,{ key: 0, name: '수수료', column: 'feeVat'}
					,{ key: 0, name: '정산지급금', column: 'calAmount'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){

			return this.items.filter(function(item){
				return item
			})
		}
		,upper_name: function(){
			let name = this.supBranch.branchName
			if(this.supBranch.distributorName){
				name += ' > ' + this.supBranch.distributorName
			}else if(this.supBranch.agencyName){
				name += ' > ' + this.supBranch.agencyName
			} else if(this.supBranch.resellerName){
				name += ' > ' + this.supBranch.resellerName
			}

			return name
		}
	}
	,methods: {
		getData: async function(){

			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'calculate/shop/history/' + this.item.calIdx + '/' + this.item.shopIdx
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.$set(this.search, 'total_count', result.data.tableList.totalCount)
					this.summary = result.data.statistics
					this.supBranch = result.data.supBranch
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.is_excel = false
			this.excel_data.content = this.items
			this.is_excel = true
		}
	}
	,created() {
		this.getData()
	}
}
</script>